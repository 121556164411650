import { Component } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { FormBuilder, Validators } from '@angular/forms';
import moment from 'moment';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationService } from '../0-shared/services/notification.sevice';
import { Account, AccountService, PaymentRequestTaskService, UnattendedPaymentRequestTaskInput } from '../0-shared/_generated/backend';

@Component({
    selector: 'app-recall',
    templateUrl: './recall.component.html',
    styleUrl: './recall.component.scss'
})
export class RecallComponent {
    recallForm = this.fb.nonNullable.group({
        paymentRequestId: ['', [Validators.required]]
    });

    constructor(
        private fb: FormBuilder,
        private notificationService: NotificationService,
        private paymentRequestTaskService: PaymentRequestTaskService) {
    }

    async submit() {
        try {
            this.validateForm();

            const formValue = this.recallForm.getRawValue();
            const taskId = formValue.paymentRequestId;

            const response = await firstValueFrom(this.paymentRequestTaskService.recall(taskId));
            if (response && response.error) {
                this.notificationService.showErrorSnackBar(`Error: ${response.error}`);
            } else {
                this.notificationService.showSuccessSnackBar('Recall was successfull.');
            }
        } catch (error: any) {
            if (error instanceof HttpErrorResponse) {
                if (error.status === 400) {
                    const errorMessage = error.error.message || error.message;
                    this.notificationService.showErrorSnackBar(errorMessage);
                } else {
                    console.error(error);
                    this.notificationService.showErrorSnackBar('Server error.');
                }
            } else {
                console.error(error);
                this.notificationService.showErrorSnackBar('Unexpected error.');
            }
        }
    }

    validateForm() {
        if (this.recallForm.invalid) {
            throw new Error('Invalid form field!');
        }
    }
}
