import { HttpErrorResponse } from '@angular/common/http';
import { FormGroup } from '@angular/forms';
import { NotificationService } from '../0-shared/services/notification.sevice';

export function determinePayeeAccountNumber(formValue: any): string {
    if (formValue.payee.customAccountNumber) {
        return formValue.payee.customAccountNumber;
    } else {
        return formValue.payee.preDefinedAccountNumber;
    }
}

export function validateForm(form: FormGroup) {
    if (form.invalid) {
        throw new Error('Invalid form field!');
    } else if (form.getRawValue().payee.customAccountNumber === '' &&
        form.getRawValue().payee.preDefinedAccountNumber === '') {
        throw new Error('Payee account number is required.');
    }
}

export function errorHandler(error: any, notificationService: NotificationService) {
    if (error instanceof HttpErrorResponse) {
        if (error.status === 400) {
            const errorMessage = error.error.message || error.message;
            notificationService.showErrorSnackBar(errorMessage);
        } else {
            console.error(error);
            notificationService.showErrorSnackBar('Server error.');
        }
    } else {
        console.error(error);
        let message = 'Unexpected error.';
        if (error.message === 'Payee account number is required.') {
            message = error.message;
        }
        notificationService.showErrorSnackBar(message);
    }
}
