import { Component, OnInit } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { FormBuilder, Validators } from '@angular/forms';
import moment from 'moment';
import { NotificationService } from '../0-shared/services/notification.sevice';
import { Account, AccountService, PaymentRequestTaskService, UnattendedPaymentRequestTaskInput } from '../0-shared/_generated/backend';
import { determinePayeeAccountNumber, errorHandler, validateForm } from '../helpers/form-helper';

@Component({
    selector: 'app-init-unattended',
    templateUrl: './init-unattended.component.html',
    styleUrl: './init-unattended.component.scss'
})
export class InitUnattendedComponent implements OnInit {

    accounts: Account[] = [];

    initUnattendedForm = this.fb.nonNullable.group({
        validityDate: ['', [Validators.required]],
        deadline: ['', [Validators.required]],
        payee: this.fb.nonNullable.group({
            customAccountNumber: ['', []],
            preDefinedAccountNumber: ['', []]
        }),
        payer: this.fb.nonNullable.group({
            accountNumber: ['', [Validators.required]],
            name: ['', [Validators.required]]
        }),
        amount: [10, [Validators.required, Validators.min(1)]],
        amountModifiable: [false, [Validators.required]],
        comment: ['']
    });

    constructor(
        private fb: FormBuilder,
        private notificationService: NotificationService,
        private accountService: AccountService,
        private paymentRequestTaskService: PaymentRequestTaskService
    ) { }

    async ngOnInit() {
        this.initUnattendedForm.controls.validityDate.setValue(moment().add(1, 'month').toISOString());
        this.initUnattendedForm.controls.deadline.setValue(moment().add(1, 'month').toISOString());

        this.accounts = await firstValueFrom(this.accountService.getAcounts());
    }

    async submit() {
        try {
            validateForm(this.initUnattendedForm);
            const formValue = this.initUnattendedForm.getRawValue();

            const input: UnattendedPaymentRequestTaskInput = {
                ...formValue,
                validityDate: moment(formValue.validityDate).format('YYYY-MM-DD'),
                deadline: new Date(formValue.deadline),
                payee: {
                    accountNumber: determinePayeeAccountNumber(formValue)
                }
            };

            // fix default value of empty field
            if (input.comment === null || input.comment === '') {
                delete input.comment;
            }

            const response = await firstValueFrom(this.paymentRequestTaskService.initUnattended(input));
            if (response.error) {
                this.notificationService.showErrorSnackBar(`Error: ${response.error}`);
            } else {
                this.notificationService.showSuccessSnackBar(`Success! Id: ${response.paymentRequestTaskId}`);
            }

        } catch (error: any) {
            errorHandler(error, this.notificationService);
        }
    }
}
